var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('default-layout',{attrs:{"fluid":"","title":_vm.timetable ? _vm.timetable.name : null,"title-chip":_vm.timetable != null && _vm.timetable.active ? _vm.$t('timetables.active').toString() : null}},[_c('div',{staticClass:"d-flex"},[_c('v-dialog',{attrs:{"max-width":"600","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-3",attrs:{"color":"primary","exact":"","text":"","disabled":_vm.timetable === null}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-pencil")]),_vm._v(" "+_vm._s(_vm.$t("timetables.edit_timetable"))+" ")],1)]}}]),model:{value:(_vm.editTimetableDialog),callback:function ($$v) {_vm.editTimetableDialog=$$v},expression:"editTimetableDialog"}},[(_vm.editTimetableDialog)?_c('edit-timetable-form',{attrs:{"timetable":_vm.timetable},on:{"onClose":function($event){_vm.editTimetableDialog = false},"onSubmit":_vm.onSubmitEditTimetable}}):_vm._e()],1),_c('v-btn',{staticClass:"mb-3",attrs:{"color":"error","exact":"","text":"","disabled":_vm.timetable == null,"loading":_vm.isBusyDelete},on:{"click":_vm.onDeleteTimetable}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-delete")]),_vm._v(" "+_vm._s(_vm.$t("timetables.delete_timetable"))+" ")],1),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"600","persistent":"","scrollable":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-3",attrs:{"color":"primary","exact":"","text":"","disabled":_vm.timetable === null}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-email")]),_vm._v(" "+_vm._s(_vm.$t("timetables.send_bulk_message"))+" ")],1)]}}]),model:{value:(_vm.sendBulkMessageDialog),callback:function ($$v) {_vm.sendBulkMessageDialog=$$v},expression:"sendBulkMessageDialog"}},[(_vm.sendBulkMessageDialog)?_c('timetable-bulk-message-dialog',{attrs:{"timetable-id":_vm.timetableId},on:{"onClose":function($event){_vm.sendBulkMessageDialog = false}}}):_vm._e()],1)],1),(_vm.timetable != null)?_c('timetable-detail',{staticClass:"mb-3",attrs:{"timetable":_vm.timetable}}):_c('v-skeleton-loader',{staticClass:"mb-3",attrs:{"type":"card"}}),_c('info-copy-alert',{attrs:{"text":_vm.$t('timetables.application_submission_link', {
                link: _vm.applicationsSubmissionLink,
            }).toString(),"copy-text":_vm.applicationsSubmissionLink,"show":_vm.timetable != null && this.getLoggedInAdmin !== null}}),_c('page-subtitle',{attrs:{"title":_vm.$t('timetables.signed_in_children').toString()}}),_c('timetable-child-to-timetables-table',{attrs:{"timetable-id":_vm.timetableId}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }